import { createAsyncThunk } from '@reduxjs/toolkit';
import Repository, {apiUrl, serializeQuery} from '../../repository/Repository';
import {error, success} from "../../error/Error";

export const getSearchJobs = createAsyncThunk('SEARCH_JOBS/getSearchJobs', async (data) => {
    return await Repository.get(`${apiUrl}/search-job/read${serializeQuery(data)}`).then((res) => res);
});

export const getSearchJobsInProgress = createAsyncThunk('SEARCH_JOBS/getSearchJobsInProgress', async () => {
    return await Repository.get(`${apiUrl}/search_job/read/in-progress`);
});

export const deleteSearchJob = createAsyncThunk('SEARCH_JOBS/deleteSearchJob', async (data) => {
    return await Repository.delete(`${apiUrl}/search-job/delete?id=${data}`);
});

export const createSearchJob = createAsyncThunk('SEARCH_JOBS/createSearchJob', async (data) => {
    return await Repository.post(`${apiUrl}/search-job/create`, data).then((res) => {
        success('Search Job created successfully!');
        return res;
    }).catch(() => {
        error("Create failed, something went wrong!");
    });
});